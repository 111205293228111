import { distributors } from 'src/router/distributors'


export const adminRouters = {
  path: '/admin',
  name: 'AdminLayout',
  component: () => import('layouts/AdminLayout.vue'),
  children: [{
    path: 'home',
    name: 'AminHome',
    component: () => import('pages/Admin/AdminHome.vue'),
    meta: {
      title: '首页',
      icon: 'home',
      showSubMenu: false
    }
  }, {
    path: 'order',
    name: 'OrdersView',
    component: () => import('pages/Order/OrdersView.vue'),
    meta: {
      title: '订单管理',
      icon: 'dvr',
      showSubMenu: false
    },
    redirect: '/admin/order/list',
    children: [{
      path: 'list',
      name: 'OrderItems',
      component: () => import('pages/Order/OrderItems.vue'),
      meta: {
        title: '订单列表',
        icon: 'list_alt',
        parent: 'OrdersView',
        menu: true
      }
    }, {
      path: 'statistics',
      name: 'Statistics',
      component: () => import('pages/Order/Statistics/index.vue'),
      meta: {
        title: '统计',
        icon: 'people_alt',
        parent: 'OrdersView',
        menu: true
      }
    }]
  }, {
    path: 'customer',
    name: 'CustomerManagement',
    component: () => import('pages/Customer/CustomerView.vue'),
    meta: {
      title: '客户管理',
      icon: 'supervised_user_circle',
      showSubMenu: false
    },
    redirect: '/admin/customer/customers',
    children: [{
      path: 'customers',
      name: 'CustomersPage',
      component: () => import('pages/Customer/CustomersPage.vue'),
      meta: {
        title: '客户列表',
        icon: 'people_alt',
        parent: 'CustomerManagement',
        menu: true
      }
    }, {
      path: 'form/:id?',
      name: 'CustomerForm',
      component: () => import('pages/Customer/CustomerForm.vue'),
      meta: {
        title: '客户列表',
        icon: 'people_alt',
        parent: 'CustomerManagement',
        menu: false
      }
    }, {
      path: 'vip',
      name: 'CustomerVip',
      component: () => import('pages/Customer/CustomerVip.vue'),
      meta: {
        title: '客户月卡',
        icon: 'fa-solid fa-crown',
        parent: 'CustomerManagement',
        menu: true
      }
    }, {
      path: 'productsType',
      name: 'ProductsType',
      component: () => import('pages/Customer/ProductClassification.vue'),
      meta: {
        title: '产品分类',
        icon: 'list_alt',
        parent: 'CustomerManagement',
        menu: true
      }
    }, {
      path: 'products',
      name: 'CustomerProducts',
      component: () => import('pages/Customer/CustomerProducts.vue'),
      meta: {
        title: '产品列表',
        icon: 'fa-solid fa-crown',
        parent: 'CustomerManagement',
        menu: true
      }
    }, {
      path: 'packges',
      name: 'CustomerPackage',
      component: () => import('pages/Customer/CustomerPackage.vue'),
      meta: {
        title: '套餐列表',
        icon: 'fa-solid fa-crown',
        parent: 'CustomerPackage',
        menu: true
      }
    }, {
      path: 'order',
      name: 'CustomerOrder',
      component: () => import('pages/Customer/CustomerOrder.vue'),
      meta: {
        title: '下单设置',
        icon: 'list_alt',
        parent: 'CustomerManagement',
        menu: true
      }
    }, {
      path: 'credit',
      name: 'CustomerCredit',
      component: () => import('pages/Customer/CustomerCredit.vue'),
      meta: {
        title: '客户信贷',
        icon: 'credit_card',
        parent: 'CustomerManagement',
        menu: true
      }
    }]
  }, {
    path: 'supplier',
    name: 'SupplierManagement',
    component: () => import('pages/Supplier/SupplierView.vue'),
    meta: {
      title: '供应商管理',
      icon: 'confirmation_number',
      showSubMenu: false
    },
    redirect: '/admin/supplier/suppliers',
    children: [{
      name: 'SuppliersPage',
      path: 'suppliers',
      component: () => import('pages/Supplier/Suppliers.vue'),
      meta: {
        title: '供应列表',
        icon: 'people_alt',
        parent: 'SupplierManagement',
        menu: true
      }
    }, {
      name: 'WithdrawalManagement',
      path: 'withdrawal',
      component: () => import('pages/Supplier/WithdrawalManagement.vue'),
      meta: {
        title: '提现管理',
        parent: 'SupplierManagement',
        menu: true,
        icon: 'currency_exchange'
      }
    }, {
      name: 'SupplierForm',
      path: 'form/:id?',
      component: () => import('pages/Supplier/SupplierForm.vue'),
      meta: {
        title: '供应商开户',
        icon: 'person_add',
        parent: 'SupplierManagement',
        menu: false
      }
    }, {
      path: 'price',
      name: 'SupplierPrice',
      component: () => import('pages/Supplier/SupplierPriceSet.vue'),
      meta: {
        title: '默认价格',
        parent: 'SupplierManagement',
        icon: 'currency_yen',
        menu: true
      }
    }, {
      path: 'capability',
      name: 'SupplierCapability',
      component: () => import('pages/Supplier/SupplierCapability.vue'),
      meta: {
        title: '能力标签',
        parent: 'SupplierManagement',
        icon: 'loyalty',
        menu: true
      }
    }, {
      path: 'task',
      name: 'SupplierTask',
      component: () => import('pages/Supplier/SupplierTask.vue'),
      meta: {
        title: '任务设置',
        parent: 'SupplierManagement',
        icon: 'add_task',
        menu: true
      }
    }]
  }, distributors, {
    path: 'credits',
    name: 'CreditManagementView',
    component: () => import('pages/Credit/CreditManagement.vue'),
    meta: {
      title: '信贷管理',
      icon: 'account_balance',
      showSubMenu: false
    },
    redirect: '/admin/credits/items',
    children: [{
      name: 'CreditItems',
      path: 'items',
      component: () => import('pages/Credit/Credits.vue'),
      meta: {
        title: '信贷机构',
        icon: 'people_alt',
        parent: 'CreditManagementView',
        menu: true
      }
    }]
  }, {
    path: 'system-settings',
    name: 'SystemSettingsView',
    component: () => import('pages/SystemSettings/SystemSettingsView.vue'),
    meta: {
      title: '系统设置',
      icon: 'settings',
      showSubMenu: false
    },
    redirect: '/admin/system-settings/financial',
    children: [{
      path: 'financial',
      name: 'FinancialSettings',
      component: () => import('pages/SystemSettings/FinancialSettings.vue'),
      meta: {
        title: '财务设置',
        parent: 'SystemSettingsView',
        menu: true,
        icon: 'account_balance_wallet'
      }
    }, {
      path: 'personnel-management',
      name: 'PersonnelManagement',
      component: () => import('pages/SystemSettings/PersonnelManagement.vue'),
      meta: {
        title: '人员管理',
        parent: 'SystemSettingsView',
        menu: true,
        icon: 'people_alt'
      }
    }, {
      path: 'advanced-configuration',
      name: 'AdvancedConfiguration',
      component: () => import('pages/SystemSettings/AdvancedConfiguration.vue'),
      meta: {
        title: '高级配置',
        parent: 'SystemSettingsView',
        menu: 'true',
        icon: 'settings_applications'
      }
    }]
  }]
}
